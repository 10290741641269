<template>
  <v-main>
    <v-container>
      <v-layout align-center justify-center> </v-layout>
      <h3 class="mb-5 mt-5">Post List</h3>
      <v-text-field
        placeholder="Search Post"
        v-model="searchQuery"
        prepend-icon="mdi-magnify"
        clearable
      >
        Search Post
      </v-text-field>
      <section v-if="errored">
        <p>
          We're sorry, we're not able to retrieve this information at the
          moment, please try back later
        </p>
      </section>
      <div v-if="loading">Loading...</div>
      <v-btn to="/createpost"> Create Post </v-btn>
      <v-data-table
        :headers="headers"
        :items="filterPost"
        :items-per-page="20"
        :sort-by="['language_id', 'sort']"
        :sort-desc="[false, false]"
        multi-sort
        item-key="id"
        class="elevation-1"
      >
        <template v-slot:top>
          <v-toolbar flat>
            <v-toolbar-title>Post List</v-toolbar-title>
            <v-divider class="mx-4" inset vertical></v-divider>
            <v-spacer></v-spacer>
          </v-toolbar>
        </template>
        <template v-slot:item.language_id="{ item }">
          {{ postLanguage.find((x) => x.id == item.language_id).lang_name }}
        </template>
        <template v-slot:item.post_type_id="{ item }">
          {{ postType.find((x) => x.id == item.post_type_id).name }}
        </template>
        <template v-slot:item.post_category_id="{ item }">
          {{
            postCategory.find((x) => x.id == item.post_category_id)
              .category_name
          }}
        </template>
        <template v-slot:item.feature_img_url="{ item }">
          <v-img :src="item.feature_img_url" max-height="150" max-width="150" />
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small class="mr-2" @click="editItem(item.id)">
            mdi-pencil
          </v-icon>
          <!-- <v-icon small @click="deleteItem(item.id)"> mdi-delete </v-icon> -->
        </template>
      </v-data-table>
    </v-container>
  </v-main>
</template>

<script>
// @ is an alias to /src
// import { mapGetters } from "vuex";
import { FETCH_POSTLIST, GET_REF_DATA, GET_POST } from "../store/actions.type";
export default {
  name: "PostList",
  data() {
    return {
      postCategory: [],
      postType: [],
      postLanguage: [],
      postList: [],
      searchQuery: "",
      info: null,
      loading: true,
      errored: false,
      headers: [
        { text: "Language", value: "language_id" },
        { text: "Sort", value: "sort" },
        { text: "Post Type", value: "post_type_id" },
        { text: "Category", value: "post_category_id" },
        { text: "Post Title", value: "title" },
        { text: "Feature Image", value: "feature_img_url" },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  computed: {
    filterPost() {
      if (this.searchQuery) {
        return this.postList.filter((item) => {
          return item.title
            .toLowerCase()
            .includes(this.searchQuery.toLowerCase());
        });
      } else {
        return this.postList;
      }
    },
  },
  mounted() {
    this.$store
      .dispatch(GET_REF_DATA)
      .then((data) => {
        this.loading = false;
        this.postLanguage = data.data.language;
        this.postCategory = data.data.post_category;
        this.postType = data.data.post_type;

        localStorage.setItem("postLanguage", JSON.stringify(this.postLanguage));
        localStorage.setItem("postCategory", JSON.stringify(this.postCategory));
        localStorage.setItem("postType", JSON.stringify(this.postType));

        this.$store
          .dispatch(FETCH_POSTLIST)
          .then((data) => {
            this.loading = false;
            this.postList = data.postList;
          })
          .catch(() => {
            this.loading = false;
            this.errored = true;
          })
          .finally(() => (this.loading = false));
      })
      .catch(() => {
        this.loading = false;
        this.errored = true;
      })
      .finally(() => (this.loading = false));
  },
  created() {},
  methods: {
    editItem(id) {
      this.$store
        .dispatch(GET_POST, id)
        .then((data) => {
          this.loading = false;
          var post = data;
          localStorage.setItem("selectedCategoryID", post.post_category_id);
          localStorage.setItem("post", JSON.stringify(post));
          this.$router.push({
            name: "EditPost",
            params: {
              postID: id,
            },
          });
        })
        .catch(() => {
          this.loading = false;
          this.errored = true;
        })
        .finally(() => (this.loading = false));
    },
  },
};
</script>
